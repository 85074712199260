import './LeftTiles.css';

function LeftTiles() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 816.4695 294.0221" className="absolute -bottom-2 right-5/8 w-96 sm:right-3/4 sm:w-96 lg:right-auto lg:-left-4 lg:w-1/3">
            <g id="Layer_2" data-name="Layer 2">
                <g id="lefttiles">
                <g id="farmtile" className="animate-tile-fade-in-3">
                    <polygon id="tile" className="left-tiles-cls-1" points="435.587 68.038 626.028 119.067 816.47 68.038 626.028 17.01 435.587 68.038"/>
                    <g id="farm">
                    <polygon id="frontwall" className="left-tiles-cls-2" points="489.988 67.894 489.998 19.439 526.273 29.159 526.889 77.794 489.988 67.894"/>
                    <polygon id="rightwall" className="left-tiles-cls-3" points="562.548 19.439 562.548 68.038 526.889 77.794 526.273 29.159 562.548 19.439"/>
                    <polygon id="frontroof" className="left-tiles-cls-2" points="489.998 19.439 508.136 9.72 526.273 29.159 489.998 19.439"/>
                    <polygon id="rightroof" className="left-tiles-cls-3" points="562.548 19.439 526.273 29.159 508.136 9.72 544.41 0 562.548 19.439"/>
                    <polyline id="bottomfence" className="left-tiles-cls-4" points="526.273 72.898 626.028 99.627 680.44 85.048 562.548 53.459"/>
                    <polyline id="topfence" className="left-tiles-cls-4" points="526.443 67.993 626.199 94.722 680.61 80.142 562.718 48.553"/>
                    </g>
                    <g id="tree" className="animate-tree-wind-2">
                    <path className="left-tiles-cls-3" d="M696.2005,21.0436l14.3806,22.2422-8.6855-2.3273L696.2,31.8022l-5.4818,6.1614-8.6853-2.3272Z"/>
                    <path className="left-tiles-cls-3" d="M710.5821,60.2993,696.2014,38.0571,682.0343,52.65l8.6854,2.3272,5.4817-6.1614,5.6952,9.1563Z"/>
                    <path className="left-tiles-cls-3" d="M693.442,55.8582l5.6951,1.526V69.44l-5.6951-1.526Z"/>
                    <path className="left-tiles-cls-2" d="M694.3571,21.62l14.3807,22.2422-8.6856-2.3273-5.6951-9.1563L688.8753,38.54,680.19,36.2129Z"/>
                    <path className="left-tiles-cls-2" d="M708.7388,60.8758,694.3581,38.6336,680.191,53.2264l8.6853,2.3272,5.4818-6.1614,5.6951,9.1563Z"/>
                    <path className="left-tiles-cls-2" d="M691.5987,56.4347l5.6951,1.526V70.0164l-5.6951-1.526Z"/>
                    </g>
                </g>
                <polygon id="tile-2" data-name="tile" className="left-tiles-cls-1 animate-tile-fade-in-2" points="218.058 243.024 408.381 294.022 598.822 242.994 408.613 191.995 218.058 243.024"/>
                <g id="foresttile" className="animate-tile-fade-in-2">
                    <polygon id="tile-3" data-name="tile" className="left-tiles-cls-1" points="218.361 126.244 408.802 177.272 599.244 126.244 408.802 75.215 218.361 126.244"/>
                    <g id="tree-2" data-name="tree" className="animate-tree-wind-1">
                    <path className="left-tiles-cls-3" d="M401.1544,49.4093l14.3807,22.2422L406.85,69.3242l-5.6952-9.1563-5.4817,6.1614-8.6854-2.3272Z"/>
                    <path className="left-tiles-cls-3" d="M415.536,88.6649,401.1553,66.4228,386.9882,81.0156l8.6854,2.3272,5.4817-6.1614,5.6952,9.1563Z"/>
                    <path className="left-tiles-cls-3" d="M398.3959,84.2239l5.6952,1.526V97.8056l-5.6952-1.526Z"/>
                    <path className="left-tiles-cls-2" d="M399.3111,49.9858,413.6917,72.228l-8.6855-2.3273-5.6952-9.1563-5.4818,6.1614-8.6853-2.3272Z"/>
                    <path className="left-tiles-cls-2" d="M413.6927,89.2415,399.312,66.9993,385.1449,81.5921l8.6854,2.3272,5.4817-6.1614,5.6952,9.1563Z"/>
                    <path className="left-tiles-cls-2" d="M396.5526,84.8l5.6952,1.526V98.3821l-5.6952-1.526Z"/>
                    </g>
                    <g id="tree-3" data-name="tree" className="animate-tree-wind-1">
                    <path className="left-tiles-cls-3" d="M337.6739,90.7182,352.0546,112.96l-8.6856-2.3272-5.6952-9.1563-5.4817,6.1614-8.6854-2.3272Z"/>
                    <path className="left-tiles-cls-3" d="M352.0555,129.9738l-14.3806-22.2421-14.1671,14.5928,8.6853,2.3272,5.4818-6.1614,5.6951,9.1563Z"/>
                    <path className="left-tiles-cls-3" d="M334.9154,125.5328l5.6952,1.526v12.0557l-5.6952-1.526Z"/>
                    <path className="left-tiles-cls-2" d="M335.8306,91.2947l14.3807,22.2422-8.6856-2.3273-5.6952-9.1563-5.4817,6.1614-8.6854-2.3272Z"/>
                    <path className="left-tiles-cls-2" d="M350.2122,130.55l-14.3807-22.2422L321.6644,122.901l8.6854,2.3272,5.4817-6.1614,5.6952,9.1563Z"/>
                    <path className="left-tiles-cls-2" d="M333.0721,126.1093l5.6952,1.526V139.691l-5.6952-1.526Z"/>
                    </g>
                </g>
                <g id="citytile" className="animate-tile-fade-in-1">
                    <polygon id="tile-4" data-name="tile" className="left-tiles-cls-1" points="0 184.828 190.555 235.857 381.11 184.828 190.555 133.8 0 184.828"/>
                    <g id="skyscraper">
                    <polygon className="left-tiles-cls-2" points="90.978 58.318 54.704 68.03 90.978 77.758 127.253 68.038 90.978 58.318"/>
                    <polygon className="left-tiles-cls-2" points="54.749 184.586 91.001 194.395 90.978 77.758 54.704 68.03 54.749 184.586"/>
                    <polyline className="left-tiles-cls-3" points="91.001 194.395 127.253 184.586 127.253 68.038 90.978 77.758"/>
                    </g>
                    <g id="tallhouse">
                    <polygon id="frontwall-2" data-name="frontwall" className="left-tiles-cls-2" points="154.459 216.264 154.469 167.656 190.739 177.372 190.733 225.984 154.459 216.264"/>
                    <polygon id="rightwall-2" data-name="rightwall" className="left-tiles-cls-3" points="227.008 167.656 227.008 216.232 190.733 225.984 190.739 177.372 227.008 167.656"/>
                    <polygon id="frontroof-2" data-name="frontroof" className="left-tiles-cls-2" points="154.469 167.656 172.604 157.941 190.739 177.372 154.469 167.656"/>
                    <polygon id="rightroof-2" data-name="rightroof" className="left-tiles-cls-3" points="227.008 167.656 190.739 177.372 172.604 157.941 208.873 148.226 227.008 167.656"/>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default LeftTiles;