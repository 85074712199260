import React from 'react';
import './App.css';
import greenVerticalLogoImg from './media/green-vertical-logo.svg';
import whiteVerticalLogoImg from './media/white-vertical-logo.svg';
import twitterLogoImg from './media/twitter-logo-blue.svg';
import Stars from './Stars';
import LeftTiles from './LeftTiles';
import RightTiles from './RightTiles'
import HouseBalloonTilted from './HouseBalloonTilted';
import HouseBalloonUpright from './HouseBalloonUpright';

function App() {
  return (
    <div className="App w-full h-full min-h-screen relative flex flex-col justify-center items-center bg-green sm:bg-galaxy-gradient text-center overflow-hidden">
      <Stars></Stars>
      <HouseBalloonTilted></HouseBalloonTilted>
      <HouseBalloonUpright></HouseBalloonUpright>
      <div className="rounded-3xl sm:bg-white w-30 max-w-sm p-8 sm:p-8">
        <img src={ greenVerticalLogoImg } alt="Acre" className="box-border max-w-full my-12 hidden sm:inline-block" />
        <img src={ whiteVerticalLogoImg } alt="Acre" className="box-border max-w-full my-12 sm:hidden inline-block" />
        <div className="mx-2 mb-12">Welcome to the frontier of digital real estate</div>
        <a href="https://un9m21pk0of.typeform.com/to/nitqk6q4" type="button" className="rounded-full mx-2 mb-8 bg-logo-green px-8 py-4 text-white hover:bg-logo-green-hover hover:cursor-pointer active:bg-logo-green-active">Join the movement</a>
      </div>
      <LeftTiles></LeftTiles>
      <RightTiles></RightTiles>
      <a href="https://twitter.com/Acre_HQ" className="rounded-full absolute bg-white bottom-12 left-1/2 -translate-x-1/2 p-4"><img src={twitterLogoImg} className="w-8 h-8" /></a>
    </div>
  );
}

export default App;
