import './Stars.css';

function Stars() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1640" height="138" viewBox="0 0 1640 138" fill="none" className="hidden sm:block absolute w-7/8 min-w-256 top-2">
            <path className="animate-star-pulse-1" d="M263.812 33L262.25 21.3333L256 19.6667L262.25 18.2778L263.812 8L265.062 18.2778L271 19.6667L265.062 21.3333L263.812 33Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="11.4737"/>
            <path className="animate-star-pulse-2" d="M632.208 92L631.167 85.9333L627 85.0667L631.167 84.3444L632.208 79L633.042 84.3444L637 85.0667L633.042 85.9333L632.208 92Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="11.4737"/>
            <path className="animate-star-pulse-3" d="M1091.1 59L1088.08 37.0667L1076 33.9333L1088.08 31.3222L1091.1 12L1093.52 31.3222L1105 33.9333L1093.52 37.0667L1091.1 59Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="11.4737"/>
            <path className="animate-star-pulse-4" d="M1125.25 79L1124 69.6667L1119 68.3333L1124 67.2222L1125.25 59L1126.25 67.2222L1131 68.3333L1126.25 69.6667L1125.25 79Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="11.4737"/>
            <path className="animate-star-pulse-5" d="M1623.46 41L1621.17 28.8667L1612 27.1333L1621.17 25.6889L1623.46 15L1625.29 25.6889L1634 27.1333L1625.29 28.8667L1623.46 41Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="11.4737"/>
            <path className="animate-star-pulse-6" d="M15.375 131L13.5 120.267L6 118.733L13.5 117.456L15.375 108L16.875 117.456L24 118.733L16.875 120.267L15.375 131Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="11.4737"/>
        </svg>
    )
}

export default Stars;