import './RightTiles.css'

function RightTiles() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 816.1777 218.6942" className="absolute bottom-12 left-5/8 w-96 sm:left-3/4 sm:w-96 lg:left-auto lg:-right-1/12 lg:w-1/3">
            <g id="Layer_2" data-name="Layer 2">
                <g id="righttiles">
                <polygon id="tile" className="right-tiles-cls-1 animate-tile-fade-in-6" points="435.414 51.059 625.736 102.057 816.178 51.029 625.736 0 435.414 51.059"/>
                <g id="housetile" className="animate-tile-fade-in-5">
                    <polygon id="tile-2" data-name="tile" className="right-tiles-cls-1" points="217.647 109.347 408.089 160.376 598.53 109.347 408.089 58.318 217.647 109.347"/>
                    <g id="house">
                    <polygon id="frontwall" className="right-tiles-cls-2" points="371.758 82.618 371.758 43.739 408.033 53.459 408.089 92.338 371.758 82.618"/>
                    <polygon id="rightwall" className="right-tiles-cls-3" points="444.308 43.739 444.308 82.618 408.089 92.338 408.033 53.459 444.308 43.739"/>
                    <polygon id="frontroof" className="right-tiles-cls-2" points="371.758 43.739 389.924 33.789 408.033 53.459 371.758 43.739"/>
                    <polygon id="rightroof" className="right-tiles-cls-3" points="444.308 43.739 408.033 53.459 389.924 33.789 426.254 24.299 444.308 43.739"/>
                    </g>
                    <g id="tree" className="animate-tree-wind-4">
                    <path className="right-tiles-cls-3" d="M488.9071,57.5307l14.3806,22.2422-8.6855-2.3273-5.6952-9.1562-5.4818,6.1614L474.74,72.1236Z"/>
                    <path className="right-tiles-cls-3" d="M503.2887,96.7864,488.908,74.5442,474.7409,89.1371l8.6854,2.3272,5.4817-6.1614,5.6952,9.1562Z"/>
                    <path className="right-tiles-cls-3" d="M486.1486,92.3454l5.6952,1.526v12.0557l-5.6952-1.526Z"/>
                    <path className="right-tiles-cls-2" d="M487.0637,58.1073,501.4444,80.35l-8.6856-2.3273-5.6951-9.1563-5.4818,6.1614L472.8966,72.7Z"/>
                    <path className="right-tiles-cls-2" d="M501.4454,97.363,487.0647,75.1208,472.8976,89.7136l8.6853,2.3272,5.4818-6.1614,5.6952,9.1563Z"/>
                    <path className="right-tiles-cls-2" d="M484.3053,92.9219,490,94.4479v12.0557l-5.6951-1.526Z"/>
                    </g>
                </g>
                <g id="singletreetile" className="animate-tile-fade-in-4">
                    <polygon id="tile-3" data-name="tile" className="right-tiles-cls-1" points="0 167.666 190.441 218.694 380.883 167.666 190.441 116.637 0 167.666"/>
                    <g id="tree-2" data-name="tree" className="animate-tree-wind-3">
                    <path className="right-tiles-cls-3" d="M172.3032,137.4634l14.3807,22.2422-8.6856-2.3273-5.6951-9.1562-5.4818,6.1614-8.6853-2.3272Z"/>
                    <path className="right-tiles-cls-3" d="M186.6849,176.7191l-14.3807-22.2422L158.1371,169.07l8.6853,2.3272,5.4818-6.1614,5.6951,9.1563Z"/>
                    <path className="right-tiles-cls-3" d="M169.5448,172.2781l5.6951,1.526V185.86l-5.6951-1.526Z"/>
                    <path className="right-tiles-cls-2" d="M170.46,138.04l14.3807,22.2422-8.6856-2.3273-5.6951-9.1563-5.4818,6.1614-8.6853-2.3272Z"/>
                    <path className="right-tiles-cls-2" d="M184.8416,177.2957l-14.3807-22.2422-14.1671,14.5928,8.6853,2.3272,5.4818-6.1614,5.6951,9.1563Z"/>
                    <path className="right-tiles-cls-2" d="M167.7015,172.8546l5.6951,1.526v12.0557l-5.6951-1.526Z"/>
                    </g>
                </g>
                </g>
            </g>
            </svg>
    )
}

export default RightTiles;