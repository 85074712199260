import './HouseBalloonTilted.css';

function HouseBalloonTilted() {
    return (
        <div className="hidden sm:block animate-floating-balloon-2 absolute top-1/4 left-5/6 w-16">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.6329 228.2744" className="w-full animate-balloon-wind-2">
            <g id="Layer_2" data-name="Layer 2">
                <g id="balloons">
                <g id="tilted-balloon">
                    <path className="house-balloon-tilted-cls-1" d="M10.2629,83.29l3.6554,95.9824m86.3942-83.8954L79.8309,188.1191"/>
                    <g id="house">
                    <polygon id="frontwall" className="house-balloon-tilted-cls-2" points="8.013 214.291 12.649 175.69 47.506 189.666 42.925 228.274 8.013 214.291"/>
                    <polygon id="rightwall" className="house-balloon-tilted-cls-3" points="84.681 184.342 80.044 222.943 42.925 228.274 47.506 189.666 84.681 184.342"/>
                    <polygon id="frontroof" className="house-balloon-tilted-cls-2" points="12.649 175.69 31.872 167.978 47.506 189.666 12.649 175.69"/>
                    <polygon id="rightroof" className="house-balloon-tilted-cls-3" points="84.681 184.342 47.506 189.666 31.872 167.978 69.075 162.888 84.681 184.342"/>
                    </g>
                    <circle className="house-balloon-tilted-cls-2" cx="59.3164" cy="59.3164" r="59.3095"/>
                    <path className="house-balloon-tilted-cls-3" d="M21.6223,74.4391a1.2619,1.2619,0,1,0-2.5234.04ZM95.3889,84.61a1.2619,1.2619,0,0,0-2.4752-.4925ZM19.0989,74.479,20.65,172.7251l2.5235-.0391L21.6223,74.4391Zm73.8148,9.6384L73.9158,179.605l2.4754.4934L95.3889,84.61Z"/>
                    <ellipse className="house-balloon-tilted-cls-3" cx="99.2926" cy="32.9337" rx="6.704" ry="17.1789" transform="translate(-3.8883 50.1774) rotate(-27.8375)"/>
                </g>
                </g>
            </g>
            </svg>
        </div>
    );
}

export default HouseBalloonTilted;