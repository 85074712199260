import './HouseBalloonUpright.css';

function HouseBalloonUpright() {
    return (
        <div className="animate-floating-balloon-1 hidden sm:block absolute top-1/3 right-7/8 w-16">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.4435 232.4606" className="w-full animate-balloon-wind-1" >
            <defs>
                <radialGradient id="radial-gradient" cx="-364.6665" cy="477.0303" r="1.2813" gradientTransform="matrix(8.5, -4.5, -4.5, -8.5, 5327.6656, 2457.3201)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0.8"/>
                <stop offset="1" stop-color="#fff"/>
                </radialGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="balloons">
                <g id="upright-balloon">
                    <path className="house-balloon-upright-cls-1" d="M14.0945,90.9733l16.6438,96.0986m75.6108-96.0986-8.0842,96.0986"/>
                    <g id="house">
                    <polygon id="frontwall" className="house-balloon-upright-cls-2" points="28.927 222.741 28.927 183.862 65.201 193.582 65.257 232.461 28.927 222.741"/>
                    <polygon id="rightwall" className="house-balloon-upright-cls-3" points="101.476 183.862 101.476 222.741 65.257 232.461 65.201 193.582 101.476 183.862"/>
                    <polygon id="frontroof" className="house-balloon-upright-cls-2" points="28.927 183.862 47.092 173.913 65.201 193.582 28.927 183.862"/>
                    <polygon id="rightroof" className="house-balloon-upright-cls-3" points="101.476 183.862 65.201 193.582 47.092 173.913 83.423 164.422 101.476 183.862"/>
                    </g>
                    <circle className="house-balloon-upright-cls-2" cx="60.2218" cy="60.2218" r="60.2218"/>
                    <circle className="house-balloon-upright-cls-4" cx="60.2218" cy="60.2218" r="60.2218"/>
                    <path className="house-balloon-upright-cls-1" d="M23.0637,80.7228,37.896,179.384M98.6612,80.7228l-6.22,98.6612"/>
                    <ellipse className="house-balloon-upright-cls-3" cx="92.813" cy="28.7171" rx="6.8071" ry="17.4432" transform="translate(7.8534 75.8424) rotate(-46.2087)"/>
                </g>
                </g>
            </g>
            </svg>
        </div>
    );
}

export default HouseBalloonUpright;